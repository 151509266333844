import React, { useEffect, useState } from 'react';
import db from './firebase';
import { useParams } from 'react-router-dom';

function Redirect() {
  const { slug } = useParams();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [message, setMessage] = useState('Connecting...');

  useEffect(() => {
    let query = db.collection('urls').where('slug', '==', slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        setMessage('URL not found');
      } else {
        setMessage('Thanks for your interest in me.');
        setShouldRedirect(true);
      }
    });
  }, [slug]);

  useEffect(() => {
    const handleRedirect = () => {
      if (shouldRedirect) {
        let query = db.collection('urls').where('slug', '==', slug);
        query.onSnapshot((data) => {
          let finalData = data.docs[0].data();
          let url = finalData.url;
                window.location.href = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=QUFFLUhqa0MwdWE4Ul9MbWZyaXdWdnFCbzRQTFlEU2daQXxBQ3Jtc0trV21RakZlNDFJVzdxUkNQSzhtMjFPR1JYNmZkcnFJd2V5ZV8xODRCODVjd1VzMm10Nk9MMHpEaWFXc0NEbmVLbEZVVm1MVUFDSThjX3daWkdwdEx4MG5PeFpLbzVtbkZBYU81dXVGTzU0TXVWaWo4OA&q=${url}&html_redirect=1`
        });
      }
    };

    handleRedirect();
  }, [shouldRedirect, slug]);

  return (
    <div>
      <center>
        <p>{message}</p>
      </center>
    </div>
  );
}

export default Redirect;
